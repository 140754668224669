import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
    margin: 0 0.5rem 2rem 0.5rem 
`

const Row = ({children}) => {
    return(
        <StyledRow>
            {children}
        </StyledRow>
    )
}

export default Row