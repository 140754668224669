import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'
import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import Row from 'src/components/core/row'
import Card from 'src/components/core/card'
import CardSlim from 'src/components/core/card-slim'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const CardPadding = styled.div`
  padding: 0 1rem;
`

const StyledCardSlim = styled(CardSlim)`
  padding: 0 !important;
  shadow: none !important;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 576px) {
    flex-direction: row;
    margin: 1rem 0;
  }
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 576px) {
    margin-right: ${props => (props.mgRt ? props.mgRt : '2rem')};
  }
`

const Breaker = styled.hr`
  background: #cccccc;
  margin: 1rem 2rem;
  @media (min-width: 576px) {
    margin: 2rem 5rem 3rem 5rem;
  }
`

const AboutTitle = styled.h2`
  font-size: 2.5rem;
  font-family: 'Cinzel', serif;
  font-weight: normal;
`
const AboutSubtitle = styled.h2`
  font-size: 2rem;
  font-weight: normal;
  font-family: 'Cinzel', serif;
`

const PhotosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  * {
    flex-basis: 45%;
    height: fit-content;
  }
`

const AboutTextBody = styled.p`
  font-size: 1rem;
  font-family: 'Old Standard TT', serif;
  font-family: 'Cormorant', serif;
  order: 2;
  margin: 0;
  margin-top: 1rem;
  margin: 0.5rem 0;
  @media (min-width: 576px) {
    order: 1;
    margin-top: 0;
  }
`

const CoverPhotoWrap = styled.div`
  margin-top: 1rem;
  @media (min-width: 576px) {
    flex-shrink: 0;
    flex-basis: 45%;
    order: 2;
    margin: ${props => (props.mgTop ? props.mgTop : '2rem auto')};
    max-width: ${props => (props.maxW ? props.maxW : '85%')};
  }
  border-radius: 8px;
box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
`

const MediaLink = styled.a`
display:block;
`

const AboutPage = ({ mgTop }) => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      allFile(filter: { relativeDirectory: { regex: "/about/" } }) {
        nodes {
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const aboutPhotos = data.allFile.nodes
  const martinPhotos = aboutPhotos.filter(
    photo => photo.relativeDirectory === 'about/martin'
  )
  const orwell = aboutPhotos
    .filter(photo => photo.relativeDirectory === 'about/martin/orwell')
    .map(photo => photo.childImageSharp.fluid)
  const martyrs = aboutPhotos
    .filter(photo => photo.relativeDirectory === 'about/martin/martyrs')
    .map(photo => photo.childImageSharp.fluid)
  const philip = aboutPhotos
    .filter(photo => photo.relativeDirectory === 'about/martin/philip')
    .map(photo => photo.childImageSharp.fluid)
  const sculpturePhotos = aboutPhotos
    .filter(photo => photo.relativeDirectory === 'about/sculpture')
    .map(photo => photo.childImageSharp.fluid)
  const letteringPhotos = aboutPhotos
    .filter(photo => photo.relativeDirectory === 'about/lettering')
    .map(photo => photo.childImageSharp.fluid)
  const stoneworkPhotos = aboutPhotos
    .filter(photo => photo.relativeDirectory === 'about/stonework')
    .map(photo => photo.childImageSharp.fluid)
  // console.log(
  //   aboutPhotos,
  //   martinPhotos,
  //   sculpturePhotos,
  //   stoneworkPhotos,
  //   letteringPhotos
  // )
  return (
    <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Martin Jennings - About</title>
      <meta
        name="description"
        content="MARTIN JENNINGS has been making public sculpture in Britain for many years. Martin Jennings lives and works near Stroud in Gloucestershire UK. He is a Fellow of the Royal Society of Sculptors. Small-scale bronze maquettes of the public sculptures are available to buy. For further information please contact."
      />
    </Helmet>
      <Fade clear>
        <Row>
          <Card>
            <CardPadding>
              {/* <AboutTitle>Martin Jennings</AboutTitle> */}
              <div>
                <Flex>
                  <FlexColumn mgRt="0">
                    <AboutTextBody>
                      MARTIN JENNINGS has been making public sculpture in
                      Britain for many years. His representations of great
                      writers and poets are particularly well-known: John
                      Betjeman at St Pancras Station, Philip Larkin in Hull,
                      Charles Dickens in Portsmouth and George Orwell outside
                      BBC Broadcasting House.
                    </AboutTextBody>
                    <AboutTextBody>
                      Celebrated figures from the medical profession have also
                      featured prominently, with John Radcliffe in Oxford,
                      Archibald McIndoe in East Grinstead and Mary Seacole
                      outside St Thomas’ Hospital in London. His sculpture of
                      prime minister Stanley Baldwin stands in Bewdley,
                      Worcestershire, and a seated figure of actor Ronnie Barker
                      in Aylesbury.
                    </AboutTextBody>
                    <AboutTextBody>
                      His sculptures are cast in bronze with accompanying text
                      carved in stone or slate around them. The Women of Steel
                      in Sheffield and George Orwell at the BBC each received
                      the national PMSA Marsh Award for Excellence in Public
                      Sculpture in 2017 and 2018.
                    </AboutTextBody>
                  </FlexColumn>
                </Flex>
                <CoverPhotoWrap maxW="65%">
                  <div>
                    <Img placeholderStyle={{
  opacity:  0,
}} fluid={orwell} />
                  </div>
                </CoverPhotoWrap>
              </div>

              <div>
                <Flex>
                  <FlexColumn mgRt="0">
                    <AboutTextBody>
                      Jennings studied calligraphy, lettercutting and
                      stonecarving following an MA in English Language and
                      Literature at the University of Oxford. Over the following
                      years he fulfilled numerous commissions to carve memorials
                      and architectural inscriptions for churches and
                      churchyards, cathedrals, colleges and public buildings.
                    </AboutTextBody>
                  </FlexColumn>
                </Flex>
              </div>

              <div>
                <CoverPhotoWrap>
                  <div>
                    <Img placeholderStyle={{
  opacity:  0,
}} fluid={martyrs} />
                  </div>
                </CoverPhotoWrap>
                <Flex>
                  <FlexColumn mgRt="0">
                    <AboutTextBody>
                      Increasingly he was commissioned to carve stone figure
                      sculptures and to model portrait busts from life in his
                      Oxford studio. Subjects have included record-breaking
                      athlete Roger Bannister, prime minister Edward Heath,
                      actor Edward Fox and many others.
                    </AboutTextBody>

                    <AboutTextBody>
                      In the year 2000 Jennings’s sculpture of Queen Elizabeth
                      the Queen Mother, the last to be modelled from the sitter
                      in her lifetime, was installed in St Paul’s Cathedral,
                      London.
                    </AboutTextBody>

                    <AboutTextBody>
                      London’s National Portrait Gallery owns three of
                      Jennings’s works including a bronze mask of author Philip
                      Pullman and a portrait cast in sterling silver of eminent
                      lawyer Lord Bingham of Cornhill.
                    </AboutTextBody>
                  </FlexColumn>
                </Flex>
              </div>

              <div>
                <CoverPhotoWrap>
                  <div>
                    <Img placeholderStyle={{
  opacity:  0,
}} fluid={philip} />
                  </div>
                </CoverPhotoWrap>
                <Flex>
                  <FlexColumn mgRt="0">
                    <AboutTextBody>
                    Martin Jennings lives and works near Stroud in Gloucestershire UK. He is a Fellow of the Royal Society of Sculptors.
                    </AboutTextBody>
                    <AboutTextBody>
                      All bronzes are cast at{' '}
                      <a href="https://pangolin-editions.com/" target="blank">
                        Pangolin Editions
                      </a>
                      .
                    </AboutTextBody>
                    <AboutTextBody>
                      Small-scale bronze maquettes of the public sculptures are
                      available to buy. For further information please{' '}
                      <Link to="/contact">contact</Link>.
                    </AboutTextBody>
                  </FlexColumn>
                </Flex>
              </div>
            <MediaLink href="https://www.youtube.com/watch?v=xwEFgWh66gk">Lecture at National Portrait Gallery 2019</MediaLink>
            <MediaLink href="https://www.countrylife.co.uk/luxury/art-and-antiques/in-focus-the-sculptor-whose-work-treads-that-fine-line-between-likeness-and-caricature-217067">Article in Country Life</MediaLink>
            <MediaLink href="/pdfs/Martin Jennings.pdf">Article in 3rd Dimension magazine</MediaLink>
            <MediaLink href="https://www.bbc.co.uk/programmes/b06ts10b">BBC Radio 4 Great Lives interview about Charles Sargeant Jagger</MediaLink>
            <MediaLink href="https://rebekahmuses.home.blog/2019/05/21/interview-with-martin-jennings/#content">Interview in Making a Start</MediaLink>
            </CardPadding>
          </Card>
        </Row>
      </Fade>
    </Layout>
  )
}

export default AboutPage
