import React from "react"
import theme from "src/components/theme/theme"
import shadow from "src/components/theme/shadows"
import styled from "styled-components"

const StyledCard = styled.div`
  padding: 1rem;
  border-radius: 8px;
  box-shadow: ${shadow(16)};
  background-color: #f3f3f3;
  justify-content: space-between;
  z-index: 10;
`
const Card = ({ children }) => {
  return <StyledCard>{children}</StyledCard>
}

export default Card
